<template>
      <v-card
        elevation="2"
        width="100%"
        class="my-5 px-4 "
      >
        <v-card-title class="my-1 text-h5 text--primary">データ容量
          <v-btn 
            icon
            :disabled="loading"
            @click="reload"
          ><v-icon >{{icons.reload}}</v-icon>
          </v-btn>
        </v-card-title>
        <v-container  style="max-width:1700px; margin-left:0;" class=" py-2" >
        <!-- <v-row :class="$vuetify.breakpoint.xs? '':'ml-5'" justify="start"> -->
          <v-row  :justify="$vuetify.breakpoint.mdAndDown? 'start':'start'" >
            <v-col v-for="value in storageData " :key="value.name" cols="auto" :class="$vuetify.breakpoint.mdAndDown? 'ma-0 pa-0':''">
            <Storage 
              :item="value"
              :loading="loading"
                />
            
            </v-col>
          </v-row>
        <!-- </v-row> -->
        </v-container>
      </v-card>
      

</template>

<script>
import { mdiReload } from '@mdi/js';

const  Storage = () => import('@/components/Storage.vue');
// import {  mapGetters } from 'vuex'
// import axios from "axios"

export default {
  components: { Storage },
  name: 'CardStorage',

  data: () => ({
    icons:{"reload":mdiReload},
    storageData:[[],[],[],[],[],[],[]],
    storageList:["navi","3Dmodel","image","pdf","sound","movie","url"],
    storageNameList:["ナビ","3Dモデル","画像","PDF","音声","動画","URL"],
    loading:true,
  }),

  async created (){
    await this.getData();
  },

  computed: {
    // storeのuser_model userIdを監視
    isUser: function(){
      return this.$store.state.user_model.userData.userId;
    }
  },

  watch:{
    isUser(){
      this.getData();
    }
  },

  methods:{
    //アカウントのデータを最新のものに更新する
    reload(){
      this.loading = true;
      this.$store.dispatch("resetUserData");
      this.$store.dispatch("setUserInfo",this.$store.state.auth.login );
      // this.loading = false;
    },
    //vueのstoreに保存されているデータを取得する
    getData() {
      if(this.$store.state.user_model.userData.userId){
        this.storageData = [];
        // console.log( this.$store.state.user_model.userData)
        for(let i = 0; i < this.storageList.length; i++){
            this.$store.state.user_model.userData[this.storageList[i]].name = this.storageNameList[i]
            this.storageData.push(this.$store.state.user_model.userData[this.storageList[i]])
          }
          this.loading = false;
        }else{
          this.loading = true;
        }
    },
  }
}
</script>



<style lang="scss" >
  @import "@/assets/main.scss";

  .Title > h1 {
    color: gray;
    font-weight: 200;
    border-bottom: 1px solid gray;
  }

  .left-border {
    border-right: 2px solid rgb(214, 214, 214);
  }

  



</style>

